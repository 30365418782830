import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  AppBar,
  Toolbar,
  makeStyles,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { AuthContext, TokenType } from '../../lib/auth';
import { useAppTheme, AppTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { GetCurrentUser_currentUser as User } from '../../schema';
import { GlobalPermission } from '../../lib/types';
import { ClientConfigContext } from '../../lib/client-config';
import { Permission } from '../../lib/types';

const HEADER_HEIGHT = 64;

const useStyles = makeStyles<AppTheme>(theme => ({
  root: {
    boxShadow: '0px 1px 2px -2px rgba(0,0,0,0.3)',
  },
  toolbar: {
    display: 'flex',
  },
  logoImg: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: `${HEADER_HEIGHT - 18}px`,
    display: 'block',
  },
  nav: {
    marginLeft: 60,
    height: HEADER_HEIGHT,
    display: 'flex',
    flexGrow: 1,
    '& a': {
      color: 'black',
      paddingLeft: 30,
      paddingRight: 30,
      fontSize: 20,
      fontWeight: 500,
      paddingTop: 15,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    '& a.active': {
      borderBottomColor: theme.palette.highlight.main,
      borderBottomStyle: 'solid',
      borderBottomWidth: 4,
    },
  },
  menuUserName: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.grey[300],
    cursor: 'default',
  },
  menuNavLink: {
    '& a': {
      textDecoration: 'none',
      color: 'black',
      width: '100%',
    },
    '&.active': {
      borderLeftColor: theme.palette.highlight.main,
      borderLeftStyle: 'solid',
      borderLeftWidth: 4,
    },
  },
  menuSpacer: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[300],
    borderTopStyle: 'solid',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: 0,
  },
}));

interface Props {
  user: User;
}

type NavLink = {
  title: string;
  to: string;
  active: boolean;
};

function getLogoutUrlFromToken(token: TokenType): string {
  if (token) {
    const parts = token.split('.');
    if (parts && parts.length == 3) {
      const decoded = atob(parts[1]);
      if (typeof decoded === 'string') {
        const parsed = JSON.parse(decoded);
        if (
          parsed &&
          parsed.logoutUrl &&
          typeof parsed.logoutUrl === 'string'
        ) {
          return parsed.logoutUrl;
        }
      }
    }
  }

  return '/';
}

function getModifiedHubUrl(url: string) {
  if (!url) return '/';
  const parsedUrl = new URL(url);
  return new URL('/hub', parsedUrl.origin).href;
}

const Header: React.FC<Props> = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const theme = useAppTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(700));
  const {
    config: { interfaceLanguages, name, uppSsoUrl },
  } = useContext(ClientConfigContext);
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const logout = () => {
    const logoutUrl = getLogoutUrlFromToken(authContext.token);
    authContext.setToken(null);

    document.location.href = logoutUrl;
  };

  const changePassword = () => {
    history.push('/change-password');
  };

  const setLocale = (language: string) => {
    void i18n.changeLanguage(language);
  };

  const navLinks: Array<NavLink> = [
    {
      visible: user.globalPermissions.includes(
        GlobalPermission.ACCESS_STORE_LIST,
      ),
      to: '/stores',
      title: t('components.header.stores'),
      active: location.pathname.startsWith('/stores'),
    },
    {
      visible: user.globalPermissions.includes(
        GlobalPermission.ACCESS_STORE_LIST,
      ),
      to: '/iterations',
      title: t(`components.header.iterations.${name}`),
      active: location.pathname.startsWith('/iterations'),
    },
    {
      visible: user.globalPermissions.includes(
        GlobalPermission.ACCESS_TASK_LIST,
      ),
      to: '/tasks',
      title: t('components.header.tasks'),
      active: location.pathname.startsWith('/tasks'),
    },
    {
      visible: user.globalPermissions.includes(
        GlobalPermission.SET_SCHEDULED_PUBLISHING_TARGET,
      ),
      to: '/publishing-targets',
      title: t('components.header.publishingTargets'),
      active: location.pathname.startsWith('/publishing-targets'),
    },
    {
      visible: user.globalPermissions.includes(
        GlobalPermission.ACCESS_DASHBOARD,
      ),
      to: '/dashboard',
      title: t('components.header.dashboard'),
      active: location.pathname.startsWith('/dashboard'),
    },
  ].filter(l => l.visible);

  return (
    <AppBar
      position="static"
      color="inherit"
      elevation={1}
      className={classes.root}
    >
      <Toolbar className={classes.toolbar}>
        <Box
          display="flex"
          height={`${HEADER_HEIGHT}px`}
          padding="8px 0"
          alignItems="center"
        >
          {uppSsoUrl ? (
            <a href={getModifiedHubUrl(uppSsoUrl)} rel="noopener noreferrer">
              <img
                src={theme.assets.logo}
                alt="logo"
                className={classes.logoImg}
              />
            </a>
          ) : (
            <Link to="/">
              <img
                src={theme.assets.logo}
                alt="logo"
                className={classes.logoImg}
              />
            </Link>
          )}
        </Box>
        <div className={classes.nav}>
          {!mobile &&
            navLinks.map(link => (
              <Link
                key={link.to}
                to={link.to}
                className={clsx(
                  'MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary',
                  link.active && 'active',
                )}
              >
                {link.title}
              </Link>
            ))}
        </div>
        <div>
          <Button
            aria-controls="header-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
            }}
            variant="text"
            endIcon={<MenuIcon />}
          >
            {!mobile && user.name}
          </Button>
          <Menu
            id="header-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {mobile && (
              <>
                {navLinks.map(link => (
                  <MenuItem
                    key={link.to}
                    className={clsx(
                      classes.menuNavLink,
                      link.active && 'active',
                    )}
                  >
                    <Link to={link.to}>{link.title}</Link>
                  </MenuItem>
                ))}
                <MenuItem className={classes.menuUserName}>
                  {user.name}
                </MenuItem>
              </>
            )}
            {interfaceLanguages.length > 1 && (
              <>
                {interfaceLanguages.map(il => (
                  <MenuItem
                    key={il.locale}
                    onClick={() => setLocale(il.locale)}
                  >
                    {t(
                      `enums.InterfaceLanguageLocaleInItsOwnLanguage.${il.locale}`,
                    )}
                  </MenuItem>
                ))}
                <MenuItem className={classes.menuSpacer} />
              </>
            )}
            {user.permissions.includes(Permission.USER_CHANGE_PASSWORD) && (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  changePassword();
                }}
              >
                {t('components.header.change-password')}
              </MenuItem>
            )}
            <MenuItem className={classes.menuSpacer} />
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                logout();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
